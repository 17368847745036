<template>

  <div>
    
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              /> -->

              <b-button
                variant="info"
                class="mobile-margin-bottom mr-1"
                @click="resendMail('multi', [])"
                :disabled="!(selected.length > 0)"
                v-if="checkPermission($route.name, 'Verify') && email_status == 'pending'"
              >
                <span class="text-nowrap">Verify</span>
              </b-button>

              <b-button
                variant="info"
                class="mobile-margin-bottom mr-1"
                @click="resendMail('multi', [])"
                :disabled="!(selected.length > 0)"
                v-if="checkPermission($route.name, 'Send Email') && email_status == 'failed'"
              >
                <span class="text-nowrap">Send Email</span>
              </b-button>

              <b-button
                :disabled="!(selected.length > 0)"
                variant="primary"
                class="mobile-margin-bottom mr-1"
                @click="openPDFPopUp();"
                v-if="checkPermission($route.name, 'Download')"
              >
                <span class="text-nowrap">Download</span>
              </b-button>
              <b-button
                v-if="checkPermission($route.name, 'Export') && email_status == 'sent'"
                :disabled="!(items.length > 0)"
                variant="secondary"
                class=""
                @click="downloadExcel('excel')"
              >
                <span class="text-nowrap">Export</span>
              </b-button>
              
              
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative midTableRpt common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #cell(info)="items">             
							<feather-icon
							:icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
							@click="items.toggleDetails"
							/>
				</template>
        <template #head(checkbox)="items">
            <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall"/>
        </template>

        <template #cell(checkbox)="items">				
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />				
        </template>	
        
        <template #cell(email_status)="items">
            <b-badge pill :variant="variantColor(items.item.email_status)" class="text-capitalize">
              {{ items.item.email_status }}
            </b-badge>
        </template>

        <template #cell(order_id)="items">
            <span class="text-capitalize" @click="items.toggleDetails" v-if="items.item.inventory_order_id">
              {{items.item.inventory_order_id.unique_id | capitalize}}
            </span>
            <span class="text-capitalize" @click="items.toggleDetails" v-else>
              N/A
            </span>
        </template> 
        
        <template #cell(inventory_order_id)="items">
            <span class="text-capitalize">{{items.item.inventory_order_id.order_type | capitalize}}</span>
        </template> 

        <template #cell(total_amount)="items">
            <span class="text-capitalize" @click="items.toggleDetails" v-if="items.item.inventory_order_id">
              ${{ getTotalAmount(items.item.supplier_id._id, items.item.inventory_order_id.orderData) | currency}}
            </span>
            <span class="text-capitalize" @click="items.toggleDetails" v-else>
              N/A
            </span>
        </template> 
        
        <template #cell(supplier_id.name)="items">
          <span @click="items.toggleDetails">{{items.item.supplier_id.name}}</span>
        </template>

         <template #cell(site.site_name)="items">
          <span @click="items.toggleDetails">{{items.item.site.site_name}}</span>
        </template>

         <template #cell(unique_id)="items">
          <span @click="items.toggleDetails">{{items.item.unique_id}}</span>
        </template>
        <!-- <template #cell(email_log)="items"> -->
          <!-- <span v-if="items.item.email_log.length > 0">
          <button  v-if="email_status == 'sent' && items.item.email_log.length > 0" variant="outline-warning" @click="viewSentMail(items.item.email_log)">View
          </button>
          </span> -->
          <!-- <span v-if="items.item.email_log.length > 0">
            <span class="" v-for="(status, ind) in items.item.email_log" :key="ind">
              <span :class="status.email_status == 'sent' ? 'text-success' : 'text-danger'">{{ status.email }} <div v-if="ind != (items.item.email_log.length - 1)">,</div> </span>
            </span>
          </span> -->
          <!-- <span v-else>N/A</span> -->
        <!-- </template> -->
        
        <!-- <template #cell(created_at)="items">
            <span>{{sitedateTime(items.item.created_at,'DD MMM YYYY','HH:MM')}}</span>
        </template>  -->
        
        <!-- <template #cell(updated_at)="items">
            <span>{{sitedateTime(items.item.updated_at,'DD MMM YYYY','HH:MM')}}</span>
        </template>  -->

        <template #cell(actions)="items">
          <b-link  v-b-tooltip.hover.v-warning title="View" variant="outline-warning" @click="download(items.item.pdf)" v-if="checkPermission($route.name, 'Download')">
            <feather-icon icon="FileIcon" class="mr-1 mediumSize" />
          </b-link>
          
          <b-link v-if="checkPermission($route.name, 'Verify') && email_status == 'pending'" v-b-tooltip.hover.v-warning title="Verify" variant="outline-warning" @click="resendMail('single', items.item._id)">
            <feather-icon icon="MailIcon" class="mediumSize"/>
          </b-link>
          <b-link v-if="email_status == 'failed'" v-b-tooltip.hover.v-warning title="Send Mail" variant="outline-warning" @click="resendMail('single', items.item._id)">
            <feather-icon icon="MailIcon" class="mediumSize"/>
          </b-link>
        </template>

        <template #row-details="items">
						<div class="p-1 px-2 fbDetail mb-1">
							<b-row>

							<b-col cols="12" xl="12" >
								<table class="mt-2 mt-xl-0 w-100">
									<tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  TYPE
												</h6>
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                           <span class="text-capitalize">{{ items.item.inventory_order_id ? items.item.inventory_order_id.order_type : 'N/A' | capitalize }}</span>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  GENERATED BY
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span>{{ getGeneratedBy(items.item.inventory_order_id.validation_record) | capitalize }}</span>
										</td>

                    	<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 EMAIL LOG
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                     <span v-if="items.item.email_log.length > 0">
                      <button  v-if="email_status == 'sent' && items.item.email_log.length > 0" variant="outline-warning" @click="viewSentMail(items.item.email_log)">View
                      </button>
                      </span>
                      <!-- <span v-if="items.item.email_log.length > 0">
                        <span class="" v-for="(status, ind) in items.item.email_log" :key="ind">
                          <span :class="status.email_status == 'sent' ? 'text-success' : 'text-danger'">{{ status.email }} <div v-if="ind != (items.item.email_log.length - 1)">,</div> </span>
                        </span>
                      </span> -->
                      <span v-else>N/A</span>
										</td>

									</tr>
                  
                  <tr class="mb-1">
                      <th class="pb-50" style="width: 10%">
                        <div class="d-flex align-items-center">
                    
                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                           GENERATED AT
                          </h6>
                      
                        </div>
                        </div>
                      </th>
                      <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                            <span>{{sitedateTime(items.item.created_at,'DD MMM YYYY','HH:MM')}}</span>
                      </td>
                      <th class="pb-50" style="width: 10%">
                        <div class="d-flex align-items-center">
                    
                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            UPDATED AT
                          </h6>
                      
                        </div>
                        </div>
                      </th>
                      <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                          <span>{{sitedateTime(items.item.updated_at,'DD MMM YYYY','HH:MM')}}</span>
                      </td>
                     
                  </tr>
                  
									<tr class="mb-1">
										<th class="pb-50" style="width: 16%">
										<b-button
											v-ripple.400="'rgba(113, 102, 240, 0.15)'"
											variant="outline-secondary"
											@click="items.toggleDetails"
											size="sm"
											class="ml-1"
											>
											Hide Detail
											</b-button>
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
									</tr>
								</table>
							</b-col>

							</b-row>
						</div>

				</template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="pdf_export"
        ref="pdf_export"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Generate PDF"
        @ok="getPDF"
        no-close-on-backdrop
      >
        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>
          <b-row>

            <b-col md="12">

              <div class="demo-inline-spacing">
                  <b-form-radio
                    class="mb-1"
                    name="some-radios"
                    value="pdf"
                    v-model="pdf_type"
                    
                  >
                    PDF
                  </b-form-radio>

                  <b-form-radio
                    class="mb-1"
                    name="some-radios"
                    value="zip"
                    v-model="pdf_type"
                  >
                    Zip
                  </b-form-radio>
              </div>

            </b-col>
              

          </b-row>
        </b-form>
      </b-modal>
      <b-modal id="mail-modal" ref="mail-modal" centered title="" hide-footer :no-close-on-backdrop="false" modal-class="send_mail_model">
      
        <table class="table table-bordered table-dark dark-text">
            <thead>
                <tr>
                <th scope="col">S.No.</th>
                <th scope="col">To</th>
                <th scope="col">CC</th>
                <th scope="col">Status</th>
                <th scope="col">Created On</th>
                </tr>
            </thead>
            <tbody v-if="mailArray.length > 0">
                <tr v-for="(mail, ind) in mailArray" :key="ind">
                    <th scope="row">{{ind+1}}</th>
                    <td v-if="mail.emailLogData.length > 0">
                      <span class="" v-for="(emailLog, ind1) in mail.emailLogData" :key="ind1" >
                        <span class="mail_with_comma" v-if="emailLog.email_type == 'to'" :class="emailLog.status == 'sent' ? 'text-success' : 'text-danger'">{{ emailLog.email }} <div v-if="ind1 != (emailLog.length - 1)">,</div> </span>
                      </span>
                    </td>
                    <td v-else>-</td>
                    <td v-if="mail.emailLogData.length > 0">
                    <span class="" v-for="(emailLog, ind1) in mail.emailLogData" :key="ind1">
                      <span class="mail_with_comma" v-if="emailLog.email_type == 'cc'" :class="emailLog.status == 'sent' ? 'text-success' : 'text-danger'">{{ emailLog.email }} <div v-if="ind1 != (emailLog.length - 1)">,</div> </span>
                    </span>
                    <td v-else>-</td>
                    <td> {{ mail.email_status | capitalize }} </td>
                    <td> {{mail.created_at | dateTime}} </td>
                </tr>
            </tbody> 
        </table>
   
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormGroup, BFormRadio,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, BAlert, VBTooltip, BFormCheckbox,BForm, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive';
import moment from "moment";
import Bus from "../../../event-bus";
export default {
  props:["siteData", "supplierData", "email_status", 'start', 'end'],
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormSelect, 
    BFormSelectOption,
    BFormRadio
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  watch: {
    siteData: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    supplierData: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    email_status: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    start: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    end: function (newVal, oldVal) { // watch it
      this.filterTable();
    }
  },
  data() {
    return {

        tableColumns: [
          { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
          { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
          { key: 'unique_id', label: 'Id', sortable: false , thStyle:  {width: '19%'}},
          { key: 'order_id', label: 'Order Id', sortable: false , thStyle:  {width: '19%'}},
          { key: 'total_amount', label: 'Amount', sortable: false , thStyle:  {width: '10%'}},
          // { key: 'total_amount', label: 'Total Amount', sortable: false , thStyle:  {width: '10%'}},
          { key: 'site.site_name', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
          { key: 'supplier_id.name', label: 'Supplier Name', sortable: false , thStyle:  {width: '20%'}},
          // { key: 'inventory_order_id', label: 'Type', sortable: false, thStyle: { width: '4%' } },
          // { key: 'generated_by.full_name', label: 'Generated By', sortable: false, thStyle: { width: '10%' } },
          // { key: 'email_log', label: 'Email Log', sortable: false, thStyle: { width: '10%' } },
          // { key: 'created_at', label: 'Generated At', sortable: false, thStyle: { width: '%' } },
          // { key: 'updated_at', label: 'Updated At', sortable: false, thStyle: { width: '%' } },
          // { key: 'email_status', label: 'Email Status', sortable: false, thStyle: { width: '12%' } },
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '10%'}},
        ],

        sites:[],
        suppliers:[],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,

        error_message:null,
        popshowDismissibleAlert:false,
        selectAll:false,
        selected:[],
        currentItem:null,

        pdf_type: 'pdf',
        mailArray: [],
    }
  },
  methods : {

    getGeneratedBy(data){
      const adminUser = data.find(item => item.role === 'admin');
      const userFullName = adminUser ? adminUser.user_fullName : data[data.length - 1].user_fullName;

      return userFullName;
    },

    openPDFPopUp(){
      this.popshowDismissibleAlert = false;
      this.pdf_type = 'pdf';
      this.$refs['pdf_export'].show();
    },
    
    getPDF(e){
      if(this.pdf_type == 'pdf'){
        this.pdfDownload();
      } else {
        this.zipDownload();
      }
      this.$refs['pdf_export'].hide();
    },
    
    pdfDownload(){
        var organization = this.$store.getters.currentUser.organization;
        var id = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
        
        var params  = "organization="+organization+"&id="+id;
        var baseurl = "";

        baseurl        = process.env.VUE_APP_SERVER_URL + '/api/purchase-order-pdf-download?' + params;
        this.selectAll = false,
        this.selected  = []
        
        window.open(baseurl,'_blank');
    },

    zipDownload(){
        var organization = this.$store.getters.currentUser.organization;
        var id = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
        
        var params  = "organization="+organization+"&id="+id;
        var baseurl = "";

        baseurl        = process.env.VUE_APP_SERVER_URL + '/api/purchase-order-zip-download?' + params;
        this.selectAll = false,
        this.selected  = []
        
        window.open(baseurl,'_blank');
    },

    getTotalAmount(supplier, data){
      var amount = 0;
      for(var i = 0; i < data.length; i++){
        if(data[i].supplier == supplier){
          var sumOfAmounts = data[i].data.filter(item => parseFloat(item.qty) > 0).reduce((total, item) => total + parseFloat(item.amount), 0);
          amount = amount + sumOfAmounts;
        }
      }
      // return Number(amount);
      return parseFloat(amount).toFixed(2)
    },
    breadCrumb(){
      var item = [{
        to: { name: 'client-dashboard' },
        text: 'Dashboard',
      }, {
        to: null,
        text: 'Inventory'
      }, {
        to: null,
        text: 'Reports'
      }, {
        to: null,
        text: 'Purchase Order',
        active: true
      }];;
      return item;
    },
    downloadExcel(){
     /* if(this.items.length){*/
         
        var keyword = this.searchQuery;
        var sortBy = this.sortBy;
        var sortDirection = this.isSortDirDesc;
        var page = this.currentPage;
        var rowsPerPage = this.perPage;
        var role = this.$store.getters.currentUser.role;

        var site = this.siteData != '' ? this.siteData :null;
        var supplier = this.supplierData != '' ? this.supplierData :null;
        var email_status = this.email_status;
            
        var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
        var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";

        var params = "keyword="+keyword+"&user_id="+this.$store.getters.currentUser._id+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&supplier="+supplier+"&role="+role+
        "&page="+page+"&rowsPerPage="+rowsPerPage+"&start="+start+"&end="+end+"&email_status="+email_status+"&organization="+ this.$store.getters.currentUser.organization;
        
        var baseurl = "";

        
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/purchase-order-sent-excel-download?"+params;
        
        window.open(baseurl,'_blank');
    },
    dataList(){
      return this.$store.dispatch(POST_API, {
            data:{
              page: this.currentPage,
              keyword: this.searchQuery,
              rowsPerPage:this.perPage,
              sortBy:this.sortBy,
              sortDirection:this.isSortDirDesc,
              role : this.$store.getters.currentUser.role,
              site : this.siteData != '' ? this.siteData :null,
              supplier : this.supplierData != '' ? this.supplierData :null,
              email_status : this.email_status,
              start: moment(this.start).format('YYYY-MM-DD'),
              end: moment(this.end).format('YYYY-MM-DD'),
            },
            api: '/api/purchase-order-list'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.items = this.$store.getters.getResults.data.docs;
                this.totalRecords = this.$store.getters.getResults.data.total;
                this.perPage = this.$store.getters.getResults.data.limit;
                this.from = this.$store.getters.getResults.data.from
                this.to = this.$store.getters.getResults.data.to
                Bus.$emit('updateCountPO', this.$store.getters.getResults.data.pending)
                return this.items;
            }
        });
    },
    viewSentMail(mailArr){
      this.mailArray = mailArr;
      this.$refs['mail-modal'].show();
    },
    resendMail(type, data){
      var arr = null;

      if(type == 'single'){
         arr = [data];
      } else {
        arr = this.selected;
      }
      Bus.$emit('showLoader');
      return this.$store.dispatch(POST_API, {
            data:{
              selected : arr,
              type : type
            },
            api: '/api/purchase-order-resend-email'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                // setTimeout(() => {
                // console.log(this.$store.getters.getResults);
                  if(this.$store.getters.getResults.errors.length > 0){
                    this.customAlert(this.$store.getters.getResults.message, '', 'warning').then(() => {
                      this.filterTable();
                      Bus.$emit('hideLoader');
                    });
                  } else {
                    this.successAlert().then(() => {
                      this.filterTable();
                      Bus.$emit('hideLoader');
                    });
                  }
                // }, 5000);
              }
        });
    },

    download(pdf) {
      window.open(pdf + '?' + new Date(), '_blank');
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
      this.selected = [];
			this.selectAll = false;
    },
    selectall(e){
			this.selected = [];		
			if (e) {
				var selected = [];

				this.items.forEach(function(item){
					selected.push(item._id);
				})
				this.selected = selected;
			}
		},
    variantColor(status){
      if (status == 'pending') {
        return 'warning';
      } else if (status == 'failed') {
        return 'danger';
      } else{
        return 'success';
      }
    }
    
  },
  mounted(){
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.border-danger {
  border: 2px solid #ea5455 !important;
  padding: 2px !important;
}
.border-secondary {
  border: 2px solid rgb(221, 221, 221) !important;
  padding: 2px !important;
}
.card-body{
  padding-top: 1rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
