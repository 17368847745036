import { render, staticRenderFns } from "./InventoryPurchaseOrderComponent.vue?vue&type=template&id=206ac762&scoped=true&"
import script from "./InventoryPurchaseOrderComponent.vue?vue&type=script&lang=js&"
export * from "./InventoryPurchaseOrderComponent.vue?vue&type=script&lang=js&"
import style0 from "./InventoryPurchaseOrderComponent.vue?vue&type=style&index=0&id=206ac762&lang=scss&scoped=true&"
import style1 from "./InventoryPurchaseOrderComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206ac762",
  null
  
)

export default component.exports